<template>
    <div class="m-2">
        <div class="content-container checkout-box border radius-anchor">
            <div>
                <div class="text-center">
                    <h2 class="text-2xl font-bold mb-3">{{ $t('tables.404.title') }}</h2>
                    <h3 class="text-lg">{{ $t('tables.404.subtitle') }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Table404',
}
</script>
